import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import MainContent from 'components/MainContent/MainContent';
import { Helmet } from 'react-helmet';
import newsBg from '../images/news/news-background.png';

const Header = styled('div')`
  background: url(${newsBg});
  position: relative;
  left: 0;
  right: 0;
  height: 9.0625rem;
`;

const HeaderTextContainer = styled('div')`
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  height: 5.625rem;
`;

const HeaderText = styled('h2')`
  margin: 3.5rem;
`;

const PrivacyPage = () => {
  return (
    <MainContent>
      <Helmet>
        <title>Privacy Policy</title>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/privacy/`} />
      </Helmet>
      <Header>
        <HeaderTextContainer>
          <HeaderText>Privacy Policy</HeaderText>
        </HeaderTextContainer>
      </Header>
      <Container>
        <>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '2rem',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Last Revised: 12/23/2022
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Introduction
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              This Privacy Notice: (a) describes how We or Our affiliates and licensees collect, transfer, and manage
              the Personal Information You provide Us when You access or use Our Services; (b) identifies certain rights
              and options You may have with respect to Your Personal Information and privacy; and (c) includes the
              provisions set forth herein as well as the terms and conditions provided in Our&nbsp;
            </span>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'underline',
                WebkitTextDecorationSkip: 'none',
                textDecorationSkipInk: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Cookie Policy
            </span>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              .
            </span>
          </p>
          <ol start={2} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Privacy Notice Changes
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              We may modify this Privacy Notice from time to time to comply with Our legal obligations, and to account
              for changes to Our Services. Changes to this Privacy Notice will be posted on the Privacy Notice link on
              Our Website. The Last Revised date on the top left of the Privacy Notice will identify the date it was
              last updated.
            </span>
          </p>
          <ol start={3} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Legal Basis
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: '#ffffff',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              In order to meet Our&nbsp;
            </span>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              contractual
            </span>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: '#ffffff',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              &nbsp;obligations under the Agreement with You, We have a legitimate business interest and legal basis to
              collect, process and share Your Personal Information as set forth herein.
            </span>
          </p>
          <ol start={4} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Personal Information
                </span>
              </h1>
              <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Personal information
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . “
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Personal
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;Information” is information that identifies, relates to, describes, is capable of being
                      associated with, or could reasonably be linked, directly or indirectly, with a particular consumer
                      or household. The following are Personal Information categories of information that We may collect
                      from You:
                    </span>
                  </h2>
                  <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Contact Information
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          . Name, postal address, unique personal identifier, telephone number, online identifier,
                          Internet Protocol address, email address, company name, or other similar information.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Transaction Information
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          . Digital Collectables purchased, tickets purchased, obtained, or considered, and other
                          purchasing or consumer histories or tendencies.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Personal Network Information
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          . Browsing history, search history, information regarding Your interaction with Services, and
                          advertisements.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Visual, audio, olfactory, electronic, or similar data
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          . Physical characteristics or description, video, or photos You choose to share.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Financial Information
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          . Stripe account number and/or other financial information.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Inferences from the foregoing categories
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          . Additional demographic information reflecting Your preferences and characteristics.
                        </span>
                      </h3>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Sources of Information
                </span>
              </h1>
              <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      When You Contact Us
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      .
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;We receive Your Personal Information when You contact Us, create an Account, sign up for Our
                      newsletter and/or use Our Services for some other authorized purpose.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Social Media Platforms
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      .
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;If You access Our Website through a Facebook, Instagram, Twitter or another similar service
                      (“Social Media Platform”), We may have access to the profile information associated with Your
                      Social Media Platform account. We may also be able to access information contained in cookies
                      placed on the device used to access and use the applicable Social Media Platform. “Cookies” are
                      text-only pieces of information that a website transfers to an individual’s hard drive or other
                      website-browsing equipment for record-keeping purposes. Cookies allow Services to remember
                      important information that will make Your use of Services more convenient.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Personal Network Information
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We also collect Your public IP address when You visit Our Website. We may use Your public IP
                      address in order to determine whether certain requests are fraudulent, and We may automatically
                      cross-reference Your public IP address with Your domain. When visiting Our Website from Your
                      personal network, Your IP address and any associated domain name will be treated as Your
                      &quot;Personal Network Information&quot;.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Other Sources
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      .
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 700,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We may also either collect or receive information about You from other sources, such as service
                      providers that support Our business and publicly available sources of information.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Device Information
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . As described in further detail below, We automatically collect information from and about the
                      computers, mobile devices, and other web-connected devices that You use in connection with Our
                      Services, and We combine this information across the different devices You use. For example, We
                      use information collected about Your use of Our Services on Authorized Devices to better
                      personalize features associated with Our Services based on the type of computing device You use to
                      access Our Services. Device information may also include the following:
                    </span>
                  </h2>
                  <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Device attributes
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          :&nbsp;Information such as the operating system, hardware and software versions, battery
                          level, signal strength, available storage space, browser type, app and file names and types,
                          and plugins.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Device operations
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          :&nbsp;Information about operations performed on the device, such as whether a window is
                          foregrounded or backgrounded, or mouse movements (which can help distinguish humans from
                          bots).
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Identifiers
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          :&nbsp;Unique identifiers, device IDs, and other identifiers, such as from games, apps, or
                          accounts You use, and family device IDs (or other identifiers unique to&nbsp;Our
                          Services&nbsp;associated with the same device or Account).
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Network and connections
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          :&nbsp;Information such as the name of Your mobile operator or ISP, language, time zone,
                          mobile phone number, IP address, and network connection speed.
                        </span>
                      </h3>
                    </li>
                    <li
                      aria-level={3}
                      dir='ltr'
                      style={{
                        listStyleType: 'lower-roman',
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#010000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                        marginLeft: '-18pt',
                        paddingLeft: '18pt',
                      }}
                    >
                      <h3
                        dir='ltr'
                        style={{
                          lineHeight: '1.2',
                          textAlign: 'justify',
                          marginTop: '0pt',
                          marginBottom: '12pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Cookie data
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          : Data from Cookies stored on Your device, including Cookie IDs and settings. More information
                          about How We use Cookies is described in more detail below. However, please visit the
                          following to review Our&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'underline',
                            WebkitTextDecorationSkip: 'none',
                            textDecorationSkipInk: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Cookie Policy
                        </span>
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          .
                        </span>
                      </h3>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Use of Personal Information
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              We use Personal Information as follows:
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To respond to Your requests, make Our Services available to You and to develop new Services, and
                  improve existing Services;
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To monitor and analyze the performance and security of Our Services and to errors in and to provide
                  support for Our Services;
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To aggregated, deidentify, and/or anonymize Personal Information to operate, analyze, improve, and/or
                  optimize Our Services;
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To Communicate with You. Such communications may via&nbsp;
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#333333',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  messager &nbsp;or email
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  ;
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To prevent and detect fraud and abuse in order to protect the security of Our Services;
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To provide You with promotional communications, materials, and other third-party information that You
                  may find interesting;
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To comply with Our legal obligations which may include cooperating with law enforcement and/or court
                  orders; and
                </span>
              </h2>
            </li>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  To enforce Our rights and to perform any other action deemed necessary by Live Bash to ensure security
                  and system integrity Services and to prevent misuse of Services.
                </span>
              </h2>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              LIVE BASH RESERVES THE RIGHT TO REVIEW AND USE ANY INFORMATION TO HELP RESOLVE PROBLEMS WITH OUR SERVICES
              OR TO ENSURE COMPLIANCE WITH OUR AGREEMENT.
            </span>
          </p>
          <ol start={7} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  How We share Your information
                </span>
              </h1>
              <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Service Providers
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We share Your Personal Information with vendors who assist Us with making Services available to
                      You (“Service Providers”). Except as otherwise stated in this Privacy Notice, Service Providers
                      are required to follow Our instructions with respect to the use of Your Personal Information and
                      are not authorized by Us to use Your Personal Information beyond the scope of &nbsp;Services.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Affiliates
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We may share&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      some
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;or all of Your Personal Information with Our affiliates, channel partners, subsidiaries,
                      joint ventures, or any other company to fulfill obligations and requests, and to deliver support
                      services.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Legal Compliance
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We cooperate with government and law enforcement officials and private parties to enforce and
                      comply with the law. We may disclose Your Personal Information to government or law&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      enforcement
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;officials or private parties in response to lawful requests when We believe disclosure or
                      sharing is necessary to comply with any legal obligation, enforce the Agreement, respond to claims
                      and legal process, protect Our property and rights or a third party, protect the safety of the
                      public or any person, or prevent or stop any illegal, unethical, or legally actionable activity.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Corporate Restructuring
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We may&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      share
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;some or all of Your Personal Information in connection with or during negotiation of any
                      merger, financing, acquisition or dissolution transaction, corporate restructuring, change of
                      control, or proceedings involving sale, transfer, divestiture, or disclosure of all or a portion
                      of Our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal
                      Information may also be transferred as a business asset.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Legitimate Business Purposes
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      may
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;also disclose Your Personal Information when it may be necessary for other legitimate
                      purposes as reasonably determined by Us.
                    </span>
                  </h2>
                </li>
              </ol>
            </li>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Cookies, Tracking Technologies and Behavioral Advertising
                </span>
              </h1>
              <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Cookies and Tracking Technologies
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . We and Our partners, affiliates, analytics and Service Providers use cookies or other tracking
                      technologies such as pixel tags and web beacons. These are used in storing content information and
                      preferences, analyzing trends, administering and maintaining Services, monitoring Your interaction
                      with Services, and to gather demographic information about You . We may receive reports based on
                      the use of these technologies by these companies on an individual as well as aggregated basis.
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 700,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We use cookies to remember Your settings for authentication. You can control the use of cookies at
                      the individual browser level. If You reject cookies, You may still access and use Services, but
                      Your ability to use some features will be fundamentally diminished. These technologies help Us
                      better understand Your behavior, tell Us which parts of Services have been visited, and facilitate
                      and measure the effectiveness of advertisements and web searches. We treat information collected
                      by cookies and other technologies as non-personal information. However, to the extent that
                      Internet Protocol Address or similar identifiers are considered personal information by local law,
                      We accordingly treat these identifiers as personal information. Please see Our&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Cookie Policy
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;for additional information.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Behavioral Advertising
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      .&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We may participate in third-party behavioral advertising. This means that a third party may use
                      technology to collect information about Your use of Services so that other parties can provide
                      advertising about products and services tailored to Your interests. If You do not want third
                      parties to collect and use Your information in this manner You may opt-out at the
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;
                    </span>
                    <a href='http://optout.aboutads.info/' style={{ textDecoration: 'none' }}>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          color: '#4472c4',
                          backgroundColor: 'transparent',
                          fontWeight: 400,
                          fontStyle: 'normal',
                          fontVariant: 'normal',
                          textDecoration: 'underline',
                          WebkitTextDecorationSkip: 'none',
                          textDecorationSkipInk: 'none',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        Digital Advertising Alliance
                      </span>
                    </a>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#4472c4',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      in the United States, the&nbsp;
                    </span>
                    <a href='http://youradchoices.ca/' style={{ textDecoration: 'none' }}>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          color: '#4472c4',
                          backgroundColor: 'transparent',
                          fontWeight: 400,
                          fontStyle: 'normal',
                          fontVariant: 'normal',
                          textDecoration: 'underline',
                          WebkitTextDecorationSkip: 'none',
                          textDecorationSkipInk: 'none',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        Digital Advertising Alliance of Canada
                      </span>
                    </a>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;in Canada, or the&nbsp;
                    </span>
                    <a href='http://www.youronlinechoices.eu/' style={{ textDecoration: 'none' }}>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          color: '#4472c4',
                          backgroundColor: 'transparent',
                          fontWeight: 400,
                          fontStyle: 'normal',
                          fontVariant: 'normal',
                          textDecoration: 'underline',
                          WebkitTextDecorationSkip: 'none',
                          textDecorationSkipInk: 'none',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        European Digital Advertising Alliance
                      </span>
                    </a>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#4472c4',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      in Europe.
                    </span>
                  </h2>
                </li>
              </ol>
            </li>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  International Users
                </span>
              </h1>
              <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Canadian, Brazilian, and Mexican Residents
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . If You are a citizen of Canada, Brazil, or Mexico and have questions about the accuracy of
                      information that We have collected about You, You can have access to that&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      information
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;in order to verify and update it, unless We are permitted or required under applicable laws
                      to refuse Your access to such information. You may contact Us at the e-mail or regular mail
                      address specified in the Contacting Us section below to request access to personal information We
                      have about You, or to obtain further information about Our privacy practices. Residents of Canada
                      are also entitled to receive certain information about the collection, use, and disclosure of
                      their personal information. We collect, use, and disclose Your Personal Information with Your
                      consent, which may be express or implied. You may withdraw Your consent to the use and disclosure
                      of Your Personal Information by contacting Us at the e-mail or regular mail address specified in
                      the Contacting Us section below.
                    </span>
                  </h2>
                </li>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      United Kingdom (“U.K.”), Switzerland, and European Union Residents
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . For purposes of this section “personal data” shall have the same meaning as Personal Information
                      defined above. &nbsp;The personal data You provide Us through Services is sent directly to Us and
                      is processed in the United States. Data protection laws in the United States are likely different
                      from those of Your home country. We are a controller with respect to the processing of Your
                      personal data. When You submit Your personal data to Us, You acknowledge that Your personal data
                      will be processed in the United States in accordance with this Privacy Notice. If We transfer
                      personal data from the European Economic Area, Switzerland, or the&nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#1f1f20',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      United
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      &nbsp;Kingdom or as part of an onward transfer to one of Our Service Providers, We will transfer
                      the personal data to parties that provide appropriate safeguards for Your personal data using an
                      approved and valid personal data transfer mechanism, such as the European Union’s Standard
                      Contractual Clauses or the United Kingdom’s International Data Transfer Agreement. If You want
                      more information about how We transfer personal data, you may contact Us using the information
                      provided at the end of this Privacy Notice.
                    </span>
                  </h2>
                </li>
              </ol>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Pursuant to the applicable data protection legislation, You have the following data subject rights:
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to access the personal data that We maintain about You;
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to require Us to correct Your personal data;
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to the erasure of Your personal data;
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to request that We stop processing Your personal data;
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to not provide or to withdraw Your consent at any time;
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to suspend the processing of Your personal data;
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to transfer Your personal data to Yourself or to a third party; and
                </span>
              </h3>
            </li>
            <li
              aria-level={3}
              dir='ltr'
              style={{
                listStyleType: 'lower-roman',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '54pt',
                paddingLeft: '18pt',
              }}
            >
              <h3
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  the right to not be subject to decisions based solely on automated decision making.
                </span>
              </h3>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              To exercise Your rights, please contact using the information located in the Contacting Us section below.
              We will respond to Your request as soon as possible, but certainly within 30 days.
            </span>
          </p>
          <ol start={10} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  California Privacy Rights
                </span>
              </h1>
              <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                  aria-level={2}
                  dir='ltr'
                  style={{
                    listStyleType: 'lower-alpha',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#010000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    marginLeft: '-18pt',
                  }}
                >
                  <h2
                    dir='ltr'
                    style={{
                      lineHeight: '1.2',
                      textAlign: 'justify',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'underline',
                        WebkitTextDecorationSkip: 'none',
                        textDecorationSkipInk: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      California Shine the Light Act
                    </span>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      . Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right
                      to request from a business, with whom the California resident has an established business
                      relationship, certain information with respect to the types of Personal Information the business
                      shares with third parties for direct marketing purposes by such third party and the identities of
                      the third parties with whom the business has shared such information during the immediately
                      preceding calendar year.
                    </span>
                  </h2>
                </li>
              </ol>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              To request a copy of the information disclosure provided by Us pursuant to Section&nbsp;1798.83 of the
              California Civil Code, contact Us at the e-mail or regular mail address specified in the Contacting Us
              section below with “California Privacy Request” in the first line. Please note that under this law, We are
              not required to respond to Your request more than once in a calendar year, nor are We required to respond
              to any request that is not sent to the designated e-mail or mailing address.
            </span>
          </p>
          <ol start={2} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={2}
              dir='ltr'
              style={{
                listStyleType: 'lower-alpha',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '18pt',
              }}
            >
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'underline',
                    WebkitTextDecorationSkip: 'none',
                    textDecorationSkipInk: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  California Do Not Track Disclosure
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  . Do Not Track is a privacy preference that some Users may set in their web browsers. When a User
                  turns on the Do Not Track signal, the browser sends a message to websites requesting them not to track
                  the User. At this time, We do not recognize or respond to Do Not Track browser settings or signals and
                  We will still receive information. As a result, We may still collect information about You and Your
                  internet activity, even if You have turned on the Do Not Track signal.
                </span>
              </h2>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              It is possible that some or all of Our third-party advertising partners or members of their affiliate
              network may participate in consumer opt-out programs. To learn more about internet-based advertising and
              consumer opt-out programs go to&nbsp;
            </span>
            <a href='http://aboutads.info/choices' style={{ textDecoration: 'none' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Arial',
                  color: '#0000ff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'underline',
                  WebkitTextDecorationSkip: 'none',
                  textDecorationSkipInk: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                http://aboutads.info/choices
              </span>
            </a>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              &nbsp;or&nbsp;
            </span>
            <a href='http://www.networkadvertising.org/choices' style={{ textDecoration: 'none' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Arial',
                  color: '#0000ff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'underline',
                  WebkitTextDecorationSkip: 'none',
                  textDecorationSkipInk: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                http://www.networkadvertising.org/choices
              </span>
            </a>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              .
            </span>
          </p>
          <ol start={11} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Nevada State Privacy Rights
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: '#ffffff',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              This Privacy Notice provides detailed information about Our privacy practices and policies as required by
              SB-200. In addition to the disclosures provided herein, SB-200 also provides Nevada State residents with
              the right to opt-out the sale of Personal Information. We do not sell Your Personal Information; however,
              We will notify You if this changes by updating this Privacy Notice to meet the SB-200 Personal Information
              sale requirements.
            </span>
          </p>
          <ol start={12} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Children Under the Age of 13
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Services are not intended for children under 13 years of age. We do not knowingly collect Personal
              Information from children under 13. If You are under 13, do not use Our Services. If We learn We have
              collected or received Personal Information from a child under 13, We will take reasonable steps to delete
              that information and/or obtain consent from Your parent or guardian (as applicable).
            </span>
          </p>
          <ol start={13} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Information Security and Confidentiality
                </span>
              </h1>
            </li>
          </ol>
          <p
            dir='ltr'
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '12pt',
            }}
          >
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore,
              although We comply with Our legal obligations in respect to the security of Your Personal Information, We
              cannot guarantee its absolute security. We employ (and require Our Service Providers and Resellers to
              maintain) generally accepted standards of organizational, administrative, physical, procedural, and
              technological measures designed to protect Your Personal Information from improper loss or misuse, and
              unauthorized access, disclosure, alteration, and destruction during processing. If You have any questions
              about the security of Your Personal Information, You can contact Us at the e-mail or regular mail address
              specified in the Contacting Us section below.
            </span>
          </p>
          <ol start={14} style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir='ltr'
              style={{
                listStyleType: 'decimal',
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#010000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
                marginLeft: '-18pt',
                paddingLeft: '18pt',
              }}
            >
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.2',
                  textAlign: 'justify',
                  marginTop: '0pt',
                  marginBottom: '12pt',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Contacting Us
                </span>
              </h1>
            </li>
          </ol>
          <p>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              If You have questions or complaints about how We use Your Personal Information, please contact Us
              at:&nbsp;
            </span>
            <a href='mailto:privacy@livebash.com' style={{ textDecoration: 'none' }}>
              <span
                style={{
                  fontSize: '12pt',
                  fontFamily: 'Arial',
                  color: '#0000ff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'underline',
                  WebkitTextDecorationSkip: 'none',
                  textDecorationSkipInk: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                privacy@livebash.com
              </span>
            </a>
            <span
              style={{
                fontSize: '12pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              .&nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
        </>
      </Container>
    </MainContent>
  );
};

export default PrivacyPage;
